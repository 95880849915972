//Scss
import "./Filter.scss";
//Container
import Container from "../container/Container";
//React
import { useRef, useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Icons
import { TbCurrencyDram } from "react-icons/tb";
import { IoIosArrowDown } from "react-icons/io";
//Components
import WatchesFilter from "./watches-filter/WatchesFilter";
import BeltsFilter from "./belts-filter/BeltsFilter";
import useOutsideClick from "../../utils/hooks/useOutsideClick";
import RangeSlider from "./range-slider/RangeSlider";

import { useTranslation } from "react-i18next";
//Config

import { filterSortingDataConfig } from "../../config";
import {
  changeBeltsFilterArrangement,
  changeFilterSortActive,
  changeWatchesFilterArrangement,
} from "../../redux/slices/filterHistorySlice";

const Filter = ({
  filterName,
  implementWatchFilter,
  implementBeltFilter,
  beltsFilterHistory,
  watchesFilterHistory,
}) => {
  const { t } = useTranslation();

  const filterSortActive = useSelector(
    (state) => state.filterHistory.sortActive
  );

  const dispatch = useDispatch();
  const {
    i18n: { language },
  } = useTranslation();

  const [filterOpen, setFilterOpen] = useState({
    beltOpen: false,
    watchOpen: false,
  });

  const [selectOpen, setSelectOpen] = useState(false);

  const refWatch = useRef(null);
  const refBelt = useRef(null);
  const refPrice = useRef(null);

  useOutsideClick(refWatch, () => {
    if (filterOpen.watchOpen) {
      setFilterOpen((prev) => {
        return {
          ...prev,
          watchOpen: false,
        };
      });
    }
  });

  useOutsideClick(refBelt, () => {
    if (filterOpen.beltOpen) {
      setFilterOpen((prev) => {
        return {
          ...prev,
          beltOpen: false,
        };
      });
    }
  });

  useOutsideClick(refPrice, () => {
    if (selectOpen) {
      setSelectOpen(false);
    }
  });

  return (
    <div className="filter">
      <Container>
        <div className="filter-inner">
          {filterName === "watches" ? (
            <div ref={refWatch}>
              <WatchesFilter
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
                lang={language}
                implementWatchFilter={implementWatchFilter}
                watchesFilterHistory={watchesFilterHistory}
              />
            </div>
          ) : (
            ""
          )}
          {filterName === "belts" ? (
            <div ref={refBelt}>
              <BeltsFilter
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
                lang={language}
                implementBeltFilter={implementBeltFilter}
                beltsFilterHistory={beltsFilterHistory}
              />
            </div>
          ) : (
            ""
          )}
          <div className="filter-inner-rangeSlider">
            <div className="filter-inner-rangeSlider-value">
              {filterName === "watches"
                ? watchesFilterHistory.price.min
                : beltsFilterHistory.price.min}
              <TbCurrencyDram />
            </div>
            <RangeSlider
              implementWatchFilter={implementWatchFilter}
              implementBeltFilter={implementBeltFilter}
              filterName={filterName}
              min={filterName === "watches" ? 10000 : 3000}
              max={filterName === "watches" ? 120000 : 15000}
              step={filterName === "watches" ? 2000 : 1000}
              value={
                filterName === "watches"
                  ? watchesFilterHistory.price
                  : beltsFilterHistory.price
              }
            />

            <div className="filter-inner-rangeSlider-value">
              {filterName === "watches"
                ? watchesFilterHistory.price.max
                : beltsFilterHistory.price.max}
              <TbCurrencyDram />
            </div>
          </div>

          <div
            className="filter-inner-selection"
            onClick={() => setSelectOpen((prev) => !prev)}
            ref={refPrice}
          >
            <div className="filter-inner-selection-select">
              <div>{t(filterSortActive["translation_key"])}</div>
              <div className="filter-inner-selection-select-icn">
                <IoIosArrowDown
                  style={{
                    transform: selectOpen ? "rotate(-180deg)" : "",
                    transition: "transform .2s linear",
                  }}
                />
              </div>
            </div>

            {selectOpen && (
              <div className="filter-inner-selection-options">
                {filterSortingDataConfig &&
                  filterSortingDataConfig.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="filter-inner-selection-options-option"
                        onClick={() => {
                          if (
                            JSON.stringify(filterSortActive) !==
                            JSON.stringify(item)
                          ) {
                            dispatch(changeFilterSortActive(item));

                            filterName === "watches"
                              ? dispatch(
                                  changeWatchesFilterArrangement(item.type)
                                )
                              : dispatch(
                                  changeBeltsFilterArrangement(item.type)
                                );
                          }
                        }}
                      >
                        {t(item.translation_key)}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Filter;
