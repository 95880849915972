import { createSlice } from "@reduxjs/toolkit";
import {
  filterBeltConfig,
  filterKindsConfig,
  filterGenderConfig,
  filterSortingDataConfig,
} from "../../config";

const initialState = {
  watchesFilter: {
    gender: filterGenderConfig[0].type,
    type: filterKindsConfig[0].type,
    price: {
      min: 10000,
      max: 120000,
    },
    arrangement: filterSortingDataConfig[0].type,
  },
  sortActive: filterSortingDataConfig[0],
  beltsFilter: {
    belt: filterBeltConfig[0].type,
    price: {
      min: 3000,
      max: 15000,
    },
    arrangement: filterSortingDataConfig[0],
  },
};

const filterHistorySlice = createSlice({
  name: "filterHistory",
  initialState,
  reducers: {
    changeWatchesFilterHistory(state, { payload }) {
      state.watchesFilter = payload;
    },
    changeWatchesFilterPrice(state, { payload }) {
      state.watchesFilter = {
        ...state.watchesFilter,
        price: payload,
      };
    },
    changeFilterSortActive(state, { payload }) {
      state.sortActive = payload;
    },
    changeWatchesFilterArrangement(state, { payload }) {
      state.watchesFilter = {
        ...state.watchesFilter,
        arrangement: payload,
      };
    },
    changeBeltsFilterHistory(state, { payload }) {
      state.beltsFilter = payload;
    },
    changeBeltsFilterPrice(state, { payload }) {
      state.beltsFilter = {
        ...state.beltsFilter,
        price: payload,
      };
    },
    changeBeltsFilterArrangement(state, { payload }) {
      state.beltsFilter = {
        ...state.beltsFilter,
        arrangement: payload,
      };
    },
  },
});

export const {
  changeWatchesFilterHistory,
  changeBeltsFilterHistory,
  changeWatchesFilterPrice,
  changeFilterSortActive,
  changeWatchesFilterArrangement,
  changeBeltsFilterArrangement,
  changeBeltsFilterPrice,
} = filterHistorySlice.actions;

export default filterHistorySlice.reducer;
