import "./PageBanner.scss";

const PageBanner = ({ mainTitle, BannerImage, height }) => {
  return (
    <div
      className="page-banner"
      style={{
        height: BannerImage ? "" : "150px",
      }}
    >
      <div
        className={
          height ? "h-450 page-banner-background" : "page-banner-background"
        }
      >
        {BannerImage && <img src={BannerImage} alt="banner" />}
      </div>
      <div className="page-banner-inner">
        <h1
          style={{
            display: mainTitle ? "block" : "none",
          }}
        >
          {mainTitle}
        </h1>
      </div>
    </div>
  );
};

export default PageBanner;
