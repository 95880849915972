import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastVisitedWatch: 0,
  lastVisitedBelt: 0,
  watchOffset: 0,
  beltOffset: 0,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    changeWatchVisit(state, { payload }) {
      state.lastVisitedWatch = payload;
    },
    changeBeltVisit(state, { payload }) {
      state.lastVisitedBelt = payload;
    },
    changeWatchOffset(state, { payload }) {
      state.watchOffset = payload;
    },
    changeBeltOffset(state, { payload }) {
      state.beltOffset = payload;
    },
  },
});

export const {
  changeWatchVisit,
  changeBeltVisit,
  changeWatchOffset,
  changeBeltOffset,
} = paginationSlice.actions;

export default paginationSlice.reducer;
