import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  changeBeltsFilterPrice,
  changeWatchesFilterPrice,
} from "../../../redux/slices/filterHistorySlice";

import "./RangeSlider.scss";
import {
  changeBeltOffset,
  changeBeltVisit,
  changeWatchOffset,
  changeWatchVisit,
} from "../../../redux/slices/paginationSlice";

const RangeSlider = ({
  min,
  max,
  value,
  step,
  filterName,
  implementWatchFilter,
  implementBeltFilter,
}) => {
  const [minValue, setMinValue] = useState(value ? value.min : min);
  const [maxValue, setMaxValue] = useState(value ? value.max : max);

  const dispatch = useDispatch();

  useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMinChange = (e) => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value, maxValue - step);
    if (!value) setMinValue(newMinVal);
    if (filterName === "watches") {
      dispatch(changeWatchesFilterPrice({ min: newMinVal, max: maxValue }));
      dispatch(changeWatchVisit(0));
      dispatch(changeWatchOffset(0));
      implementWatchFilter();
    } else {
      dispatch(changeBeltsFilterPrice({ min: newMinVal, max: maxValue }));
      dispatch(changeBeltVisit(0));
      dispatch(changeBeltOffset(0));
      implementBeltFilter();
    }
  };

  const handleMaxChange = (e) => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value, minValue + step);
    if (!value) setMaxValue(newMaxVal);
    if (filterName === "watches") {
      dispatch(changeWatchesFilterPrice({ min: minValue, max: newMaxVal }));
    } else {
      dispatch(changeBeltsFilterPrice({ min: minValue, max: newMaxVal }));
    }
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <div className="wrapper">
      <div className="input-wrapper">
        <input
          className="input"
          type="range"
          value={minValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
        />
        <input
          className="input"
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
        />
      </div>

      <div className="control-wrapper">
        <div className="control" style={{ left: `${minPos}%` }} />
        <div className="rail">
          <div
            className="inner-rail"
            style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
          />
        </div>
        <div className="control" style={{ left: `${maxPos}%` }} />
      </div>
    </div>
  );
};

export default RangeSlider;
