import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  changeWatchOffset,
  changeWatchVisit,
} from "../../redux/slices/paginationSlice";
import {
  filterWatches,
  getWatchesThunk,
} from "../../redux/slices/watchesSlice";

import ReactPaginate from "react-paginate";
import Container from "../container/Container";
import Filter from "../filter/Filter";
import WatchesList from "./watches-list/WatchesList";
import Loading from "../loading/Loading";
import RequestError from "../errors/request-error/RequestError";

import "../../styles/Pagination.css";
import "./Watches.scss";

const Watches = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const watchesFilterHistory = useSelector(
    (state) => state.filterHistory.watchesFilter
  );

  useEffect(() => {
    implementWatchFilter();
  }, [watchesFilterHistory]);

  const implementWatchFilter = useCallback(() => {
    dispatch(filterWatches(watchesFilterHistory));
  }, [dispatch, watchesFilterHistory]);

  useEffect(() => {
    async function getDataRequest() {
      await dispatch(getWatchesThunk());
      implementWatchFilter();
    }
    getDataRequest();
  }, [dispatch]);

  const watchesData = useSelector((state) => state.watches.filterList);
  const loading = useSelector((state) => state.watches.watchListLoading);
  const error = useSelector((state) => state.watches.watchListError);
  const forcePage = useSelector((state) => state.pagination.lastVisitedWatch);

  let itemsPerPage = 12;
  const itemOffset = useSelector((state) => state.pagination.watchOffset);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = watchesData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(watchesData.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % watchesData.length;
    dispatch(changeWatchOffset(newOffset));
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(changeWatchVisit(event.selected));
  };

  return (
    <section className="watches">
      <Container>
        <Filter
          filterName="watches"
          implementWatchFilter={implementWatchFilter}
          watchesFilterHistory={watchesFilterHistory}
        />
        {watchesData && watchesData.length !== 0 && currentItems && (
          <WatchesList data={currentItems} />
        )}
        {currentItems.length === 0 && (
          <h1
            style={{
              fontSize: "60px",
              textAlign: "center",
              marginTop: "60px",
              color: "#ececec",

              textShadow:
                "1px 0px 1px #CCCCCC, 0px 1px 1px #EEEEEE, 2px 1px 1px #CCCCCC, 1px 2px 1px #EEEEEE, 3px 2px 1px #CCCCCC, 2px 3px 1px #EEEEEE, 4px 3px 1px #CCCCCC, 3px 4px 1px #EEEEEE, 5px 4px 1px #CCCCCC, 4px 5px 1px #EEEEEE, 6px 5px 1px #CCCCCC, 5px 6px 1px #EEEEEE, 7px 6px 1px #CCCCCC",
            }}
          >
            {t("not-found")}
          </h1>
        )}
        {loading && <Loading />}
        {error && <RequestError />}

        {watchesData && watchesData.length !== 0 && currentItems && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            forcePage={forcePage}
            className="pagination"
          />
        )}
      </Container>
    </section>
  );
};

export default Watches;
